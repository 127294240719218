import { React, createRef } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig, ValidateEmail, normalize } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  verfiyDomain,
  addNewClient,
  listAgents,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { PhoneNumberUtil } from "google-libphonenumber";

const auth = new HelperClass();

class Addclient extends Component {
  constructor() {
    super();
    this.state = {
      serviceData: [],
      select_service: "",
      errorMessage: "",
      serviceId: "",
      title: "",
      name: "",
      logo: "",
      client_email: "",
      client_phone: "",
      client_company: "",
      client_password: "",
      c_password: "",
      client_address: "",
      client_address2: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      client_country: null,
      description: "",
      successMessage: "",
      tableLoader: true,
      domain: "",
      step: 1,
      dnsList: [],
      a_ip: "",
      is_verified: 0,
      AgentsList: [],
      select_agent: "",
      notifyCheckBox: true,
      account_status: "",
      passwordShown: false,
      confirmPasswordShown: false,
      errorSelectAgent: "",
      errorName: "",
      errorClientStatus: "",
      errorClientCompany: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientAddress: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientCountry: "",
      errorClientPassword: "",
      errorCPassword: "",
      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",
      passwordRequired: false,
    };
    this.countryOptions = countryList()
      .getData()
      .filter(
        (country) => country.label !== "United States Minor Outlying Islands"
      );
    this.phoneInputRef = createRef();
  }

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };

  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown,
    });
  };

  async componentDidMount() {
    const servicesResponce = await listAgents(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("agentsResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        formLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          if (servicesResponce.data.data[i][5] === "active__Yes") {
            all_clients.push(servicesResponce.data.data[i]);
          }
        }
      }
      this.setState({
        AgentsList: all_clients,
      });
    }
  }

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        //console.log("addressComponents: ", data.results);
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });
        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }
        this.setState({
          client_city: city && city !== "" ? city : "",
          client_state: state && state !== "" ? state : "",
          client_zip: zipCode && zipCode !== "" ? zipCode : "",
          client_country: country && country !== "" ? country : "",
          client_address:
            streetAddress && streetAddress !== "" ? streetAddress : "",
          client_address2: address2 && address2 !== "" ? address2 : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;
    this.setState({
      client_country: country,
    });
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    if (name === "name") {
      value = value.replace(/[^a-zA-Z\s]/g, "");
    } else if (name === "title") {
      value = value.replace(/[^a-zA-Z0-9\s -]/g, "");
    } else if (name === "client_company") {
      value = value.replace(/[^a-zA-Z0-9\s -]/g, "");
    }
    this.setState({
      [name]: value,
    });
  };

  handleChangeStep = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
    });
    //await this.sendFileData(event.target.files[0]);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
    console.log("STEP: ", this.state.step);
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  VerifyConnection = async () => {
    const {
      title,
      name,
      domain,
      logo,
      client_email,
      client_password,
      c_password,
      client_company,
    } = this.state;
    console.log("domain_name: ", domain);
    if (domain) {
      const servicesResponce = await verfiyDomain(
        auth.getAccount(),
        auth.getToken(),
        domain
      );
      console.log("DNS RESPONSE: ", servicesResponce.data.dnsList);
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while verifying the domain.",
          tableLoader: false,
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        if (servicesResponce.data.dnsList.DNSData) {
          console.log(
            "DNS : ",
            servicesResponce.data.dnsList.DNSData.dnsRecords[0].address
          );
          let local_ip = process.env.REACT_APP_AGENT_PORTAL_DNS;
          let domain_dns =
            servicesResponce.data.dnsList.DNSData.dnsRecords[0].address;

          if (local_ip == domain_dns) {
            this.setState({
              successMessage:
                "Domain is verified. Now you can proceed with adding the client.",
              is_verified: 1,
              a_ip: domain_dns,
            });
          } else {
            this.setState({
              successMessage: "",
              errorMessage:
                "Your A record is not pointed to our IP address, which is " +
                local_ip +
                ". For more information please click View Instruction button.",
              is_verified: 0,
            });
          }
        } else {
          this.setState({
            errorMessage: "There is some error while verifying the domain.",
            tableLoader: false,
            disabled: false,
            is_verified: 0,
          });
        }
        this.setState({
          dnsList: servicesResponce.data.dnsList,
          tableLoader: false,
          disabled: false,
          is_verified: 0,
        });
      } else {
        this.setState({
          errorMessage: "There is some error while verifying the domain.",
          tableLoader: false,
          disabled: false,
          is_verified: 0,
        });
      }
    } else {
      this.setState({
        errorMessage: "Domain name cannot be empty.",
        tableLoader: false,
        disabled: false,
        is_verified: 0,
      });
    }
  };

  formClientUpdate = async () => {
    let {
      title,
      name,
      client_email,
      client_password,
      c_password,
      client_phone,
      client_company,
      client_address,
      client_address2,
      client_city,
      client_state,
      client_zip,
      client_country,
      select_agent,
      account_status,
    } = this.state;
    this.setState({
      successMessage: "",
      errorMessage: "",
      errorSelectAgent: "",
      errorName: "",
      errorClientStatus: "",
      errorClientCompany: "",
      errorClientEmail: "",
      errorClientPhone: "",
      errorClientAddress: "",
      errorClientCity: "",
      errorClientState: "",
      errorClientZip: "",
      errorClientCountry: "",
      errorClientPassword: "",
      errorCPassword: "",
      errorPass: "",
    });
    let is_validated = true;

    if (this.state.select_agent === "") {
      this.setState({
        errorSelectAgent: "error",
        errorMessage: "Agent is required.",
      });
      is_validated = false;
    } else if (this.state.name === "") {
      this.setState({
        errorSelectAgent: "",
        errorName: "error",
        errorMessage: "Client Name is required.",
      });
      is_validated = false;
    } else if (this.state.account_status === "") {
      this.setState({
        errorName: "",
        errorClientStatus: "error",
        errorMessage: "Account Status is required.",
      });
      is_validated = false;
    } else if (this.state.client_company === "") {
      this.setState({
        errorAccountStatus: "",
        errorClientCompany: "error",
        errorMessage: "Company name is required.",
      });
      is_validated = false;
    } else if (this.state.client_email === "") {
      this.setState({
        errorClientCompany: "",
        errorClientEmail: "error",
        errorMessage: "Email address is required.",
      });
      is_validated = false;
    } else if (ValidateEmail(this.state.client_email) === false) {
      this.setState({
        errorClientCompany: "",
        errorClientEmail: "error",
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (this.state.client_phone === "") {
      this.setState({
        errorClientEmail: "",
        errorClientPhone: "error",
        errorMessage: "Phone is required.",
      });
      is_validated = false;
    } else if (!this.isPhoneValid(client_phone)) {
      this.setState({
        errorClientEmail: "",
        errorClientPhone: "error",
        errorMessage: "Invalid phone number.",
      });
      return false;
    } else if (this.state.client_address === "") {
      this.setState({
        errorClientPhone: "",
        errorClientAddress: "error",
        errorMessage: "Address is required.",
      });
      is_validated = false;
    } else if (this.state.client_city === "") {
      this.setState({
        errorClientAddress: "",
        errorClientCity: "error",
        errorMessage: "City is required.",
      });
      is_validated = false;
    } else if (this.state.client_state === "") {
      this.setState({
        errorClientCity: "",
        errorClientState: "error",
        errorMessage: "State is required.",
      });
      is_validated = false;
    } else if (this.state.client_zip === "") {
      this.setState({
        errorClientState: "",
        errorClientZip: "error",
        errorMessage: "Zip/postal code is required.",
      });
      is_validated = false;
    } else if (!this.state.client_country || this.state.client_country === "") {
      this.setState({
        errorClientZip: "",
        errorClientCountry: "error",
        errorMessage: "Country is required.",
      });
      is_validated = false;
    } else if (
      this.state.passwordRequired &&
      this.state.client_password === ""
    ) {
      this.setState({
        errorClientCountry: "",
        errorClientPassword: "error",
        errorMessage: "Password is required.",
      });
      is_validated = false;
    } else if (
      this.state.passwordRequired &&
      this.state.client_password !== ""
    ) {
      if (
        this.state.passwordRequired &&
        this.state.client_password.length < 8
      ) {
        this.setState({
          errorClientCountry: "",
          errorClientPassword: "error",
          errorMessage: "Use 8 characters or more for your password.",
        });
        is_validated = false;
      } else if (this.state.passwordRequired && this.state.c_password === "") {
        this.setState({
          errorClientPassword: "",
          errorCPassword: "error",
          errorMessage: "Confirm password is required.",
        });
        is_validated = false;
      } else if (
        this.state.passwordRequired &&
        this.state.c_password.length < 8
      ) {
        this.setState({
          errorClientPassword: "",
          errorCPassword: "error",
          errorMessage: "Use 8 characters or more for your confirm password.",
        });
        is_validated = false;
      } else if (
        this.state.client_password.trim() != this.state.c_password.trim()
      ) {
        this.setState({
          errorClientPassword: "",
          errorCPassword: "error",
          errorMessage: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (is_validated) {
      let apiResponce = await addNewClient(
        auth.getAccount(),
        auth.getToken(),
        title,
        name,
        client_email,
        client_password,
        client_phone,
        client_company,
        client_address,
        client_address2,
        client_city,
        client_state,
        client_zip,
        client_country,
        select_agent,
        this.state.notifyCheckBox.toString(),
        account_status,
        this.state.passwordRequired.toString()
      );
      console.log("Add Client Response: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 409 &&
        apiResponce.data.message === "Address validation failed"
      ) {
        const formattedErrorMessage = apiResponce.data.errors
          ? String(apiResponce.data.errors).replace(/,/g, "<br />")
          : "Address validation failed";

        this.setState({
          errorMessage: formattedErrorMessage,
          tableLoader: false,
        });
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "success"
      ) {
        this.setState({
          successMessage: "Client added successfully.",
          tableLoader: false,
        });
        setTimeout(async function () {
          window.location.replace("/clients");
        }, 3000);
      } else if (
        apiResponce.data.status === 405 &&
        apiResponce.data.message === "email_exists"
      ) {
        this.setState({
          errorMessage:
            "Email already exists. Please try different email address.",
          disabled: false,
          tableLoader: false,
        });
        return false;
      } else {
        this.setState({
          errorMessage: "Something went wrong,try again later.",
          disabled: false,
          tableLoader: false,
        });
        //auth.logout();
      }
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ logo: event.target.files[0] });
  };

  render() {
    const { useGooglePlaces, toggleButtonText } = this.state;
    const errorStyle = this.state.errorClientAddress
      ? {
          input: (provided) => ({
            ...provided,
            borderColor: "red",
          }),
          control: (provided) => ({
            ...provided,
            borderColor: "red",
          }),
        }
      : {};
    return (
      <div className="nk-content " id="">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/clients")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Clients
                  </Link>
                  <Typography color="textPrimary" className="">
                    Add New Client
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div class="card-head">
                    <h6 class="card-title">
                      Register an {auth.getCompName()} client account.
                    </h6>
                  </div>
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.errorMessage,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  <form
                    method="post"
                    encType="multipart/form-data"
                    autoComplete="off"
                  >
                    <div className="row g-4">
                      <div className="form-group col-md-6">
                        <MutextField
                          id="select_agent"
                          name="select_agent"
                          select
                          label="Select Agent"
                          value={this.state.select_agent}
                          onChange={this.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          helperText="Choose an agent to whom this client will be assigned."
                          variant="outlined"
                          fullWidth
                          error={this.state.errorSelectAgent === "error"}
                        >
                          <option key="sets100" value=""></option>
                          {this.state.AgentsList &&
                          this.state.AgentsList.length > 0 ? (
                            <>
                              {this.state.AgentsList.map(
                                (agent_list, index) => (
                                  <option
                                    key={`sets${index}`}
                                    value={agent_list[8]}
                                  >
                                    {agent_list[2]} ({agent_list[1]})
                                  </option>
                                )
                              )}
                            </>
                          ) : null}
                        </MutextField>
                      </div>
                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="name"
                          name="name"
                          type="text"
                          label="Client Name"
                          inputProps={{ maxLength: 50 }}
                          value={this.state.name}
                          onChange={this.handleChange}
                          helperText="Enter the client name."
                          variant="outlined"
                          fullWidth
                          error={this.state.errorName === "error"}
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <MutextField
                          id="title"
                          name="title"
                          type="text"
                          inputProps={{ maxLength: 30 }}
                          label="Client Title"
                          value={this.state.title}
                          onChange={this.handleChange}
                          helperText="Enter the title here."
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <MutextField
                          id="account_status"
                          name="account_status"
                          select
                          label="Select Account Status"
                          value={this.state.account_status}
                          onChange={this.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          helperText="Select the account status here."
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientStatus === "error"}
                        >
                          <option key="" value=""></option>
                          <option key="5" value="active">
                            Active
                          </option>
                          <option key="10" value="inactive">
                            Inactive
                          </option>
                        </MutextField>
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="client_company"
                          name="client_company"
                          type="text"
                          inputProps={{ maxLength: 50 }}
                          label="Company Name"
                          value={this.state.client_company}
                          onChange={this.handleChange}
                          helperText="Enter the company name."
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientCompany === "error"}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          required
                          id="client_email"
                          name="client_email"
                          type="email"
                          label="Email Address"
                          defaultValue={this.state.client_email}
                          onChange={this.handleChange}
                          helperText="Enter your email address here."
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientEmail === "error"}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <PhoneInput
                          required
                          defaultCountry={"us"}
                          inputProps={{
                            name: "client_phone",
                            id: "client_phone",
                            maxLength: 20,
                            style: this.state.errorClientPhone
                              ? { borderColor: "red" }
                              : {},
                          }}
                          dialCodePreviewStyleProps={{
                            style: this.state.errorClientPhone
                              ? { borderColor: "red" }
                              : {},
                          }}
                          value={this.state.client_phone}
                          onChange={(value) =>
                            this.handleChange({
                              target: { name: "client_phone", value },
                            })
                          }
                          helperText="Enter the phone number here."
                          label="Phone Number"
                          ref={this.phoneInputRef}
                          onFocus={this.handleFocus}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <div
                          class="nk-block-between-md"
                          style={{
                            position: "absolute",
                            zIndex: 9999,
                            right: "13px",
                            top: "-15px",
                          }}
                        >
                          <div class="nk-block-head-content">
                            <label
                              htmlFor="googleLocation-autocomplete"
                              style={{
                                color: "black",
                                fontWeight: "500",
                                marginBottom: "8px",
                              }}
                            >
                              {" "}
                            </label>
                          </div>
                          <div class="nk-block-head-content">
                            <ul class="nk-block-tools gx-3">
                              <li>
                                <a
                                  onClick={this.handleToggle}
                                  class="text-primary fs-11"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "11px",
                                  }}
                                >
                                  {toggleButtonText}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {useGooglePlaces ? (
                          <GooglePlacesAutocomplete
                            apiKey={
                              process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                            }
                            selectProps={{
                              value: this.state.client_address
                                ? {
                                    label: this.state.client_address,
                                    value: this.state.client_address,
                                  }
                                : null,
                              onChange: this.handleAddressSelect,
                              placeholder: this.state.client_address
                                ? null
                                : "Enter your address..",
                              styles: {
                                ...errorStyle,
                                input: (provided) => ({
                                  ...provided,
                                  color: "black",
                                  height: "47px",
                                }),
                                option: (provided) => ({
                                  ...provided,
                                  color: "black",
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: "black",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9,
                                }),
                              },
                            }}
                            className="googleLocation-autocomplete"
                          />
                        ) : (
                          <MutextField
                            required
                            id="client_address"
                            name="client_address"
                            type="text"
                            label="PO Box Address"
                            value={this.state.client_address}
                            inputProps={{ maxLength: 32 }}
                            onChange={this.handleChange}
                            helperText="Enter valid PO Box Address. i.e, PO Box Number, City, State Zip Code"
                            variant="outlined"
                            fullWidth
                            error={this.state.errorClientAddress === "error"}
                          />
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <MutextField
                          id="client_address2"
                          name="client_address2"
                          type="text"
                          label="Address 2"
                          value={this.state.client_address2}
                          onChange={this.handleChange}
                          inputProps={{ maxLength: 100 }}
                          helperText="If needed, enter additional address details, such as apartment number, suite, or unit."
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div
                        className="form-group col-md-2"
                        style={{ marginBottom: "0px" }}
                      >
                        <MutextField
                          required
                          id="client_city"
                          name="client_city"
                          type="text"
                          label="City"
                          value={this.state.client_city}
                          inputProps={{ maxLength: 32 }}
                          onChange={this.handleChange}
                          helperText=""
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientCity === "error"}
                        />
                      </div>
                      <div
                        className="form-group col-md-2"
                        style={{ marginBottom: "0px" }}
                      >
                        <MutextField
                          required
                          id="client_state"
                          name="client_state"
                          type="text"
                          label="State"
                          value={this.state.client_state}
                          inputProps={{ maxLength: 32 }}
                          onChange={this.handleChange}
                          helperText=""
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientState === "error"}
                        />
                      </div>
                      <div
                        className="form-group col-md-2"
                        style={{ marginBottom: "0px" }}
                      >
                        <MutextField
                          required
                          id="client_zip"
                          name="client_zip"
                          type="text"
                          label="Zip / Postal Code"
                          value={this.state.client_zip}
                          inputProps={{ maxLength: 9 }}
                          onChange={this.handleChange}
                          helperText=""
                          variant="outlined"
                          fullWidth
                          error={this.state.errorClientZip === "error"}
                        />
                      </div>
                      <div
                        className="form-group col-md-6"
                        style={{ marginBottom: "0px" }}
                      >
                        <Select
                          id="client_country"
                          className="customCountrySelect-addClient"
                          name="client_country"
                          label="country"
                          options={this.countryOptions}
                          value={{
                            label: this.state.client_country,
                            value: this.state.client_country,
                          }}
                          onChange={this.handleChangeCountry}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              minHeight: "56px",
                            }),
                            menu: (provided, state) => ({
                              ...provided,
                              zIndex: 9,
                            }),
                          }}
                          placeholder="Select Country"
                        />
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-required">
                          Select country
                        </p>
                      </div>
                      <div
                        className="custom-control custom-control-lg custom-checkbox col-lg-12"
                        style={{
                          padding: 0,
                          marginLeft: "3rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <div className="form-group">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck3"
                            checked={this.state.passwordRequired}
                            onChange={() => {
                              const isChecked = !this.state.passwordRequired;
                              this.setState({
                                passwordRequired: isChecked,
                              });
                              console.log("Required Password:", isChecked);
                            }}
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck3"
                          >
                            Add Password
                          </label>
                        </div>
                      </div>

                      {this.state.passwordRequired && (
                        <>
                          <div
                            className="form-group col-md-6"
                            style={{ position: "relative" }}
                          >
                            <MutextField
                              required
                              id="client_password"
                              name="client_password"
                              type={
                                this.state.passwordShown ? "text" : "password"
                              }
                              label="Password"
                              defaultValue={this.state.client_password}
                              inputProps={{ maxLength: 50 }}
                              onChange={this.handleChange}
                              helperText="Enter your password here."
                              variant="outlined"
                              fullWidth
                              error={this.state.errorClientPassword === "error"}
                            />
                            <a
                              style={{
                                left: "auto",
                                right: "10px",
                                top: "41px",
                                position: "absolute",
                                cursor: "pointer",
                              }}
                              tabIndex="-1"
                              onClick={this.togglePassword}
                              className="form-icon form-icon-right passcode-switch"
                              data-target="password"
                            >
                              {this.state.passwordShown ? (
                                <em className="icon ni ni-eye-off"></em>
                              ) : (
                                <em className="icon ni ni-eye"></em>
                              )}
                            </a>
                          </div>
                          <div
                            className="form-group col-md-6"
                            style={{ position: "relative" }}
                          >
                            <MutextField
                              required
                              id="c_password"
                              name="c_password"
                              type={
                                this.state.confirmPasswordShown
                                  ? "text"
                                  : "password"
                              }
                              label="Confirm Password"
                              defaultValue={this.state.c_password}
                              inputProps={{ maxLength: 50 }}
                              onChange={this.handleChange}
                              helperText="Enter the confirm password here."
                              variant="outlined"
                              fullWidth
                              error={this.state.errorCPassword === "error"}
                            />
                            <a
                              style={{
                                left: "auto",
                                right: "10px",
                                top: "41px",
                                position: "absolute",
                                cursor: "pointer",
                              }}
                              tabIndex="-1"
                              onClick={this.toggleConfirmPassword}
                              className="form-icon form-icon-right passcode-switch"
                              data-target="password"
                            >
                              {this.state.confirmPasswordShown ? (
                                <em className="icon ni ni-eye-off"></em>
                              ) : (
                                <em className="icon ni ni-eye"></em>
                              )}
                            </a>
                          </div>
                        </>
                      )}
                      <div
                        className="custom-control custom-control-lg custom-checkbox col-lg-12"
                        style={{ padding: 0, marginLeft: "3rem" }}
                      >
                        <div className="form-group">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck2"
                            checked={this.state.notifyCheckBox}
                            onChange={() => {
                              const isChecked = !this.state.notifyCheckBox;
                              this.setState({ notifyCheckBox: isChecked });
                              console.log(
                                "Notify Checkbox Checked:",
                                isChecked
                              );
                            }}
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck2"
                          >
                            Notify Client
                          </label>
                        </div>
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <hr />
                      <div className="form-group text-right col-md-12">
                        <button
                          className="btn btn-lg btn-primary"
                          type="button"
                          disabled={this.state.disabled}
                          onClick={this.formClientUpdate}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>

                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addclient);
